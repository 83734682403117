<template>
    <div>
        <component :is="component" style="height: 280px;" :value="tableData"></component>
        <v-divider v-if="cType === 'component'"></v-divider>
        <v-container grid-list-md class="padding-20" v-if="cType === 'component'">
            <v-layout row wrap>
                <v-chip outlined><span style="color:#1E88E5">TOTAL: {{formattedTableTotal}}</span></v-chip>
                <v-spacer></v-spacer>
                <v-chip outlined @click="$router.push({ path: '/dashboard/widgetSeeMoreContainer' })">SHOW MORE</v-chip>
            </v-layout>
        </v-container>
    </div>
</template>
<script>

    import { globalFunctions } from '../../../javascript/globalFunctions'
    import { widgetManager } from '../../../javascript/widgetManager'

    export default {
        data() {
            return {
                component: null,
                cType: '',
                tableData: [],
                tableTotal: 0,
                formattedTableTotal: '',
            }
        },
        methods: {
            formatAccountAmount() {
                this.formattedTableTotal = globalFunctions.formatMoney(this.tableTotal, 2, ".", ",")
            },
            showComponent: function () {
                if (this.tableData.length !== 0) {
                    this.component = () => import('../../charts/donutChartBudget.vue')
                    this.cType = "component"
                } else {
                    this.component = () => import('../noDataWidget.vue')
                    this.cType = ""
                }
            },
        },
        beforeMount() {
            let table = widgetManager.buildBudgetTotals(new Date())
            this.tableData = table.tableData
            this.tableTotal = table.tableTotal

            this.showComponent()

            this.formatAccountAmount()
        }
    }
</script>


<style>

    .red {
        color: #F44336;
    }
</style>